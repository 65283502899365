@import url("https://fonts.googleapis.com/css?family=Caveat|Cantarell");

@import "keyframes.scss";

$navBlue: #334d77;

/**** general ****/

* {
  box-sizing: border-box;
}

body {
  font-family: "Cantarell", serif;
  background-color: #fff;
  color: #34495e;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #cbccd6;
}

body::-webkit-scrollbar-thumb {
  background: $navBlue;
}

a{
  color: #cc902a;

  &:hover,
  &:visited{
    text-decoration: none;
    color: initial;
  }
}

h2 {
  margin: 10px 0;
  font-size: 2.5rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: 100;
}

p,
li {
  font-size: 1.4em;
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.5;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

.loading-trowel-wrapper {
  position: fixed;
  top: 300px;
  right: calc(50% - 40px);
  width: 80px;
  animation: brand-roll 1s infinite;
  transition: 1000ms;
  z-index: -1;
}

.general-container {
  opacity: 0;
  transition: 700ms;
}

/**** intro ****/

#intro {
  top: 0;
  position: fixed;
  z-index: -1;
  transition: 1s ease-out;

  .intro-img {
    top: 0;
    position: fixed;
    transition: 1000ms ease-out;
    z-index: -1;
  }
}



/**** about ****/

#about-section {
  display: flex;
  justify-content: center;
  transition: 600ms;

  .main-header {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &:hover .brand-img {
      animation: brand-roll 2s;
    }

    .navbar-brand {
      padding: 0 13px;

      .brand-img {
        height: 100px;
        animation: brand-roll 2s 3s;
      }
    }

    h1 {
      font-family: "Caveat", serif;
      padding: 0 0 40px 0;
      text-align: center;
      font-size: 4em;
      display: inline;
      margin: 20px 0 0 0;
      font-weight: 500;
      line-height: 100%;
    }
  }

  #about {
    padding: 0 20px;
  }
}


.about-row {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 55px;
  max-width: 1000px;
}

.about-iframe-box {
  min-height: 370px;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.about-iframe-wrapper {
  background: #81f5ca;
  width: 100%;
}

@media (max-width: 768px) {
  #about-section .main-header {
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .about-iframe-wrapper {
    width: auto;
  }
}

.about-iframe {
  width: 340px;
  height: 100%;
  border: none;
  margin: 0 auto;
  display: block;
  transition: 1s ease-out;
  transform: scale(0);
}

.show-about-iframe {
  transform: scale(1);
}

.skills-row {
  margin-top: 20px;

  li {
    text-align: left;
  }
}

.show-about {
  margin-top: 15px !important;
  opacity: 1 !important;
}

.about-me article {
  padding-bottom: 40px;
}

.cv-modal-open {
  color: #cc902a;
  font-weight: bold;
  cursor: pointer;
}

.about-tooltip {
  color: #cc902a;
  font-weight: bold;
  cursor: pointer;
}

[tooltip] {
  position: relative;
  display: inline-block;
}

[tooltip]::before,
[tooltip]::after {
  position: absolute;
  top: 6px;
  left: 50%;
  z-index: 99;
  opacity: 0;
  transition: 400ms;
}

[tooltip]::before {
  content: "";
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

[tooltip-position="top"]::before {
  left: 50%;
}

[tooltip]::after {
  content: attr(tooltip);
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  font-size: 14px;
  width: 120px;
  border-radius: 5px;
  padding: 4px 4px;
}

@media (max-width: 775px) {
  [tooltip]::after {
    width: auto;
  }
}

[tooltip-position="top"]::after {
  left: 50%;
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  transition: 400ms;
  top: -6px;
}

.learn-more {
  padding: 15px 15px 15px 0;
  float: left;
}

.modal {
  top: auto;
}

.cv-modal-close-button {
  float: right;
}

#cv-pdf {
  padding: 10px;
  height: 500px;
  width: 100%;
}

#browser-animation {
  position: relative;
  height: 100%;

  #browser-window {
    display: flex;
    margin-top: 10px;
    position: relative;
    z-index: 2;

    #html-svg {
      transition: 400ms;
      animation: htmlSVG 10s infinite;
    }

    #css-svg {
      transition: 400ms;
      animation: cssSVG 10s infinite;
    }

    #js-svg {
      transition: 400ms;
      animation: jsSVG 10s infinite;
    }

    #react-svg {
      transition: 400ms;
      animation: reactSVG 10s infinite;
    }

    #browser-svg-bg {
      transition: 400ms;
      animation: browserSVG 10s infinite;
    }
  }

  #broser-finish-img {
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    margin-top: 11px;
    z-index: 1;
  }
}

/**** work ****/

.projects-hr {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.projects-hr:first-of-type {
  margin-top: 0;
}

.project-router {
  display: none;
}

.work-container {
  background-color: #fff;

  h2 {
    text-align: center;
    margin: 10px 0;
  }

  h3 {
    margin: 20px 30px;
    font-size: 32px;
  }

  .work-tile {
    padding: 20px;

    .no-link {
      visibility: hidden;
      pointer-events: none;
    }
  }
}



.work-img {
  display: block;
  margin: auto;
  position: relative;
}

.proj-title {
  font-size: 1.7em;
}

.proj-img {
  margin-bottom: 10px;
  transition: all 0.3s ease-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.2),
      0 6px 20px 0px rgba(0, 0, 0, 0.7);
  }
}

.github-link {
  color: #231c13;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover .github-link-img {
    animation: git-roll 1.5s linear infinite;
  }

  img {
    height: 40px;
  }
}

.work-description {
  margin-top: 10px;
  height: 60px;
  font-size: 1.3em;
}

#voltad {
  background: #fff !important;
}

#voltad-iframe {
  height: 450px;
  width: 580px;
  border: none;
  padding-top: 50px;
  margin: 0 auto;
  display: block;
}

/**** contact ****/

#contact {
  background-color: #fff !important;

  .contact-box {
    background-color: #3d2500;
    background-image: url("~/public/images/natural-paper.png");
  }

  h3,
  p,
  label {
    color: #e6eaf2;
    text-decoration: none;
  }

  h3 {
    font-size: 2.5rem;
    margin: 30px 0 10px;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  textarea {
    font-family: Arial, Helvetica, sans-serif;
    height: auto !important;
  }

  .alert {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 800ms;
    padding: 0 1.25rem;
    max-height: 0;
    border: none;
  }

  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }

  .show-message {
    padding: 10px 1.25rem;
    max-height: 100%;
  }

  .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border: 1px solid #c3e6cb;

    p {
      color: #155724;
    }
  }

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border: 1px solid #f5c6cb;

    p {
      color: #721c24;
    }
  }

  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;

    p {
      color: #8a6d3b;
    }

    #sending-dots:after {
      content: ' .';
      animation: dots 3s steps(5, end) infinite;
      font-size: 20px;
      margin-left: -5px;
    }
  }

  .missingFields .error-message,
  .missingFields .required-input {
    padding: 10px 1.25rem;
    max-height: 100%;
  }

  .missingFields input:invalid,
  .missingFields textarea:invalid {
    border: 2px solid #bf1a25;
  }

  .required-input {
    color: #da3e3e;
    font-size: 15px !important;
    margin-top: 3px !important;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  #submit-button {
    margin: 10px 0 30px;
    color: #fff;
    border-color: #3a220d;
    text-transform: uppercase;
    background-color: #88765c;
    cursor: pointer;

    &:hover{
      color: #fff;
      background-color: #a28c6f;
      border-color: #583a1b;
    }
  }
}

/**** footer ****/

footer {
  padding: 10px;
  background-color: #1b1102;
  border-top: #130b01;
}

.social-media-link {
  margin-left: 10px;
  height: 40px;
}

.social-media a {
  padding: 0 auto;
}

#copyright {
  padding-left: 25px;
}

#copyright p {
  color: #fff;
  margin: 0;
}

.img-bounce {
  margin-left: 10px;

  &:hover{
    animation: bounce 0.8s linear infinite alternate;
  }
}

/**** @media ****/
@media (min-width: 768px) {
  #copyright {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .skills-row {
    margin-top: 0;
  }
}

@media (max-width: 580px) {
  #voltad-iframe {
    display: none;
  }
}

@media (max-width: 767px) {
  .social-media {
    padding-bottom: 8px;
  }

  .project-search {
    padding-right: 10px;
  }

  .work-description {
    height: 80px;
  }

  .no-link {
    display: none;
  }
}

@media (max-width: 979px) {
  .main-header h1 {
    padding: 20px;
  }
}

@media (min-width: 980px) {
  .about-row {
    padding: 40px 50px;
  }

  #about {
    transition: 1s ease-in;
    margin-top: 200px;
  }
}

@media (min-width: 1050px) {
  .work-tile {
    padding: 30px 30px 10px 30px;
  }
}

@media (min-width: 850px) and (max-width: 991px) {
  .container {
    max-width: 820px;
  }
}