@keyframes brand-roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes hamburgerAniFirst {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(4px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes hamburgerAniSecond {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-4px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes git-roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  50% {
    transform: translateY(5px);
  }
  80% {
    transform: translateY(-5px);
  }
}

// About animation 

@keyframes htmlSVG{
  0%{ 
    transform: translate3d(0,0,0);
    opacity: 1;
  } 
  10%{
    opacity: 1;
  }
  15%{
    transform: translate3d(400px, -300px, 0);
    opacity: 0;
  }
  90%{
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes cssSVG{
  0%{ 
    transform: translate3d(0,0,0);
    opacity: 1;
  } 
  15%{
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  25%{
    opacity: 1;
  }
  30%{
    transform: translate3d(-400px, -300px, 0);
    opacity: 0;
  }
  90%{
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes jsSVG{
  0%{ 
    transform: translate3d(0,0,0);
    opacity: 1;
  } 
  30%{
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  40%{
    opacity: 1;
  }
  45%{
    transform: translate3d(400px, 300px, 0);
    opacity: 0;
  }
  90%{
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes reactSVG{
  0%{ 
    transform: translate3d(150px,0,0);
    opacity: 1;
  } 
  45%{
    transform: translate3d(150px,0,0);
    opacity: 1;
  }
  55%{
    opacity: 1;
  }
  60%{
    transform: translate3d(-250px, 300px, 0);
    opacity: 0;
  }
  90%{
    transform: translate3d(150px,0,0);
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes browserSVG{
  0%{ 
    opacity: 1;
  } 
  60%{
    opacity: 1;
  }
  70%{
    opacity: 0;
  }
  90%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes dots {
  0% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #777;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #777,
      .5em 0 0 rgba(0,0,0,0);}
  100% {
    text-shadow:
      .25em 0 0 #777,
      .5em 0 0 #777;}}
